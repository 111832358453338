import React, { useEffect } from 'react'
import Layout from "../../share/layout";
import '../../../styles/font-awesome-4.7.0/css/font-awesome.css'
import Image0 from '../../../images/sonidosaudiovisuales/face.png'
import Image1 from '../../../images/sonidosaudiovisuales/creditos.png'
import Image2 from '../../../images/sonidosaudiovisuales/back.png'
import styles from '.././global.css'
import '.././movil.css'

const Creditos = () => {
    useEffect(() => {
        document.body.classList.add('body-sonidos-visuales');
        return function cleanup() {
            document.body.classList.remove('body-sonidos-visuales');
        };
    }, [])
    return (
        <Layout
            title="Geografías | UPC Cultural"
            metaTitle="Geografías | UPC Cultural"
            description="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos. "
            subtitile="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            metaDescripcin="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            imgurl="//images.ctfassets.net/bv5rnjawitjg/E9aFHf11TxaEIX6JuU3vL/550f80daa53881d13f10dc95466806e2/800_x_800-1.png?h=300"
            keywords="Multimedia, Audiovisual, Luis Alvarado, Emilio Le Roux, Vered Engelhard, Luis Sanz, Paola Torres Núñez del Prado, Jonathan Castro Alejos, Lucía Beaumont."
        >
            <section id="section-content" className="section-content">
                <div className="face"><a href="https://www.facebook.com/upccultural1/" target="_blank"><img alt="" src={Image0} /></a></div>
                <div className='page page7 pdetalles' id='page7'>
                    <div className='inner'>
                        <span className="bg1"></span>
                        <span className="bg2"></span>
                        <span className="bglogo2"><span className="logoimg"></span></span>
                        <div className='creditos'>
                            <img src={Image1} />
                        </div>
                        <div className="row">
                            <div className="col col1">
                                <p><b>Geografías de lo visible y lo audible</b><br />
                                    Seis propuestas audiovisuales</p>
                                <p><b>Curaduría:</b> Luis Alvarado</p>
                                <p><b>Artistas:</b><br />
                                    Lucía Beaumont<br />
                                    Jonathan Castro Alejos<br />
                                    Vered Engelhard<br />
                                    Emilio Le Roux<br />
                                    Luis Sanz<br />
                                    Paola Torres Núñez del Prado

                                </p>
                            </div>
                            <div className="col col2">
                                <p>Esta exhibición reúne un conjunto de obras de artistas peruanos que han venido trabajando en zonas intermedias entre las artes visuales y la música/sonido. Son artistas que se han movido en un camino intersticial. Por un lado, como resultado del impacto que las nuevas tecnologías, han ejercido en la producción de imagen y sonido, lo que ha facilitado la creación de lenguajes transdisciplinares. Por otro lado, como herencia de una comprensión de lo contemporáneo en el arte, como desafío permanente a un discurso moderno de autonomía.</p>
                                <p>Se trata también de un conjunto de artistas localizados en diversas partes del mundo. De allí que sus obras respondan también a los propios contextos internacionales en los que están inmersos. Sin embargo, es posible reconocer en cada uno algunas claves: formas de recuerdo o empleo y reformulación de ciertos signos que podemos asociar a su lugar de origen.</p>
                                <p>Pero lo que articula estas seis propuestas es que todas trabajan a partir de una noción de territorio o lugar, ya sea porque aluden directamente a la tierra, a la arena, a la ruina o al cuerpo.</p>
                                <p>La pieza de video y sonido amplificado de “the:locus:” de Jonathan Castro, se presenta como una “documentación radical”, un mapeo de ruinas urbanas que transforma y exacerba lo que es percibido en abstracciones visuales y sonoras. Percibir aquí es crear e imaginar un entorno sonoro visual distópico. El mapa sonoro es la clave también de una pieza como “Cal y Canto” de Vered Engelhard, pero aquí hay más bien una intención de hurgar en una historia personal pero también colectiva. La obra, que consiste de una partitura visual y una pieza sonora a partir de una performance, se sitúa en un espacio íntimo (ocurre en una bañera) pero también en un espacio simbólico de conflictos ambientales y sociales (la playa de Ancón que en ese momento sufrió el derrame de petróleo de una empresa transnacional).</p>
                                <p>Emilio Le Roux, por su parte, trabaja específicamente en el universo de la partitura visual (y la obra abierta), y presenta composiciones interpretadas por diversos ensambles, bajo el nombre de “Música Imaginaria”. Dichas partituras toman referencias del mundo de la abstracción pictórica, como de los mapas estelares. Luis Sanz trabaja más bien en la creación de un entorno sonoro visual digital abstracto. El título de su obra “Disociación Endémica” remite tanto a una enfermedad que afecta a una región, como a un hecho negativo que se repite frecuentemente en un lugar.</p>
                            </div>
                            <div className="col col3">
                                <p>Lucía Beaumont presenta una pieza de video y sonidos manipulados. El título de la pieza “Arena y Ceniza”, alude a diversos tipos de materia que se producen por combustión, pero como la imagen cambiante y parpadeante del video, estas dos palabras remiten también a nombres femeninos cuyos significados transforman el sentido de lo que vemos. Y finalmente, la obra interactiva de Paola Torres Núñez del Prado, titulada “Expansión (como todo)”, emplea una cámara que registra y transforma la imagen del espectador, en imaginarias y elásticas cortezas terrestres, a ritmo de música electrónica de baile, dejando sugerida la mutabilidad de las identidades que fluyen en el universo digital.</p>
                                <p>En todas estas obras el acto de escucha cumple un rol fundamental en la producción y comprensión misma de lo visual. En tal sentido, son obras que se ubican en una tradición del trabajo con sonido en las artes que hacen empleo de tecnología. Son piezas audiovisuales que se nutren y reconocen también en otras tradiciones artísticas, más cercanas al horizonte de la música y el arte sonoro, que ha incorporado lo visual como parte de su propio proceso de expansión.</p>
                                <p>Finalmente, todas estas son obras producidas en la pandemia, durante la cual la alternativa de mantener comunicación a través del espacio virtual digital empujó nuevamente los límites de mucha producción artística, lo que generó la eclosión del uso de lenguajes transmedia y de formatos audiovisuales y generó una aceleración de procesos de digitalización para casi cualquier actividad artística previamente pensada para ser presencial.</p>
                                <p>Es desde ese nuevo escenario que la obra de estos artistas adquiere una especial atención. Lo que nos ofrecen en conjunto, son aproximaciones y derivas hacia el reconocimiento de un lugar. Pero en el fondo son también deseos por romper cierta manera sofocante, incómoda, vertical, de habitar el mundo, lo que podemos encontrar aquí son tránsitos hacia un ámbito de redefinición de esa forma de habitar.</p>
                            </div>
                        </div>
                        <a className="btnback" href='/galeria/geografias'>
                            <a data-page="p0"><img src={Image2} /></a>
                        </a>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Creditos